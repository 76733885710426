import { PageProps } from 'gatsby'
import * as React from 'react'

import { FilterCategoryWidget } from '../FilterCategoryWidget'
import { FilterSearchResultWidget } from '../FilterSearchResultWidget'
import { FilterSearchWidget } from '../FilterSearchWidget'
import { HtmlWidget } from '../HtmlWidget'
import { IframeWidget } from '../IframeWidget'
import {
  LayoutProps,
  FilterSearchLayoutProps,
  Data,
  FilterSearchResultLayoutProps,
  Breadcrumb,
  HtmlLayoutProps,
  IframeLayoutProps,
  SearchParams,
  SearchPageContext,
  FilterCategoryLayoutProps,
  CategoryDictionary,
} from '../interface'

type Props = Pick<
  PageProps<Data, SearchPageContext, SearchParams>,
  'data' | 'location' | 'pageContext'
> & {
  breadcrumb: Breadcrumb
  tagCloud: boolean
  q: string[]
  page: number
  dictionary: CategoryDictionary
  onSearch: NonNullable<React.ComponentProps<typeof FilterSearchWidget>['onSearch']>
}
export function renderSearchPageWidget(layouts: LayoutProps[], props: Props) {
  return layouts.map(layout => {
    switch (layout.type) {
      case 'filterSearch': {
        const relatedCategories = props.pageContext?.category?.parent
          ? [
              {
                id: props.pageContext.category.parent,
                title: props.dictionary[props.pageContext.category.parent]?.title,
                sub: props.pageContext.category.sub,
              },
            ]
          : []
        return (
          <FilterSearchWidget
            key={layout.id}
            layout={layout as FilterSearchLayoutProps}
            data={props.data}
            location={props.location}
            q={props.q}
            tags={props.pageContext?.tags || []}
            onSearch={props.onSearch}
            breadcrumbWidgetProps={{
              breadcrumb: props.breadcrumb,
              tagCloud: !!props.data.settingYaml.tagCloud,
              relatedCategories,
              hierarchies: props.location.state?.hierarchies || [],
              dictionary: props.dictionary,
              topTitle: props.data.settingYaml.words?.breadcrumb?.top || 'よくあるご質問',
            }}
          />
        )
      }
      case 'filterSearchResult':
        return (
          <FilterSearchResultWidget
            key={layout.id}
            layout={layout as FilterSearchResultLayoutProps}
            dictionary={props.dictionary}
            data={props.data}
            q={props.q}
            page={props.page}
            onSearch={props.onSearch}
            location={props.location}
            pageContext={props.pageContext}
            botId={props.data.settingYaml.botId || ''}
          />
        )
      case 'filterCategory':
        return (
          <FilterCategoryWidget
            key={layout.id}
            layout={layout as FilterCategoryLayoutProps}
            dictionary={props.dictionary}
            location={props.location}
            pageContext={props.pageContext}
            q={props.q}
          />
        )
      case 'html':
        return <HtmlWidget key={layout.id} layout={layout as HtmlLayoutProps} />
      case 'iframe':
        return <IframeWidget key={layout.id} layout={layout as IframeLayoutProps} />
      default:
        return null
    }
  })
}
