import { useQuery, gql } from '@apollo/client'
import { Link, GatsbyLinkProps } from 'gatsby'
import * as React from 'react'

import { QAHeader, QAPanel, QAContent } from '@karakuri-ai/faq-component'

import { Data, FrequentLayoutProps } from './interface'

const TOP_FAQ = gql`
  query topPageViewFAQ($botId: ID!) {
    topPageViewFAQs(botId: $botId) {
      id
      title
    }
  }
`

interface QueryData {
  topPageViewFAQs: {
    id: string
    title: string
  }[]
}

type Props = { layout: FrequentLayoutProps; data: Data }
export function FaqWidget({ layout, data }: Props) {
  const botId = data.settingYaml.botId
  const { loading, data: topFaqData } = useQuery<QueryData>(TOP_FAQ, { variables: { botId } })
  if (
    loading ||
    !topFaqData ||
    !topFaqData.topPageViewFAQs ||
    topFaqData.topPageViewFAQs.length === 0
  ) {
    return null
  }
  return (
    <QAPanel className="faq-panel-frequent">
      <QAHeader title={`${layout.title || 'よくあるご質問'}`} />
      {topFaqData.topPageViewFAQs.map(x => {
        const LinkComponent = React.forwardRef<
          HTMLAnchorElement,
          GatsbyLinkProps<Record<string, unknown>>
        >(function LinkComponent(props, ref) {
          return <Link ref={ref as any} {...props} to={`answer/${x.id}?frequent=true`} />
        })
        return <QAContent key={x.id} question={x.title} LinkComponent={LinkComponent} />
      })}
    </QAPanel>
  )
}
