import { useApolloClient } from '@apollo/client'
import { navigate } from 'gatsby'
import * as React from 'react'

import { Search } from '@karakuri-ai/faq-component'

import { KarakuriAnalyticsEvent } from '../utils/karakuri-analytics-event'

import { GQL_SEARCH_FAQ } from './graphql'
import { Data, SearchLayoutProps } from './interface'

type Props = { data: Data; layout: SearchLayoutProps }
export function SearchWidget({ layout, data }: Props) {
  const client = useApolloClient()
  const botId = data.settingYaml.botId

  const handleSearched = (inputValue: string, result: any[]) => {
    KarakuriAnalyticsEvent.search(inputValue, result)
  }

  return (
    <div className="faq-panel faq-panel-search">
      <h3 className="faq-panel-title">{layout.title || 'キーワード検索'}</h3>
      <Search
        onClick={navigate}
        onSearched={handleSearched}
        searchWaitMs={data.settingYaml.waitMs?.search ?? 5000}
        search={inputValue =>
          client.query({
            query: GQL_SEARCH_FAQ,
            variables: {
              botId,
              inputValue: inputValue || '',
              searchType: data.settingYaml.searchType,
            },
          })
        }
        resultMessage={{
          success: data.settingYaml.words?.search?.resultMessage?.success || undefined,
          error: data.settingYaml.words?.search?.resultMessage?.error || undefined,
        }}
      />
    </div>
  )
}
