import { Button } from '@material-ui/core'
import { navigate, PageProps } from 'gatsby'
import * as React from 'react'

import { Question, QAPanel, Answer, SkillApi, CopyLinkButton } from '@karakuri-ai/faq-component'

import { Session } from '../session'
import { KarakuriAnalyticsEvent } from '../utils/karakuri-analytics-event'

import { Breadcrumb, AnswerData, TagCloud, Hierarchy, AnswerPageContext } from './interface'

type Props = Pick<
  PageProps<AnswerData, AnswerPageContext, { hierarchies?: Hierarchy[] }>,
  'data' | 'location' | 'pageContext'
> & {
  breadcrumb: Breadcrumb
  tagCloud: TagCloud
  skillApi: SkillApi
}
export function AnswerWidget({ data, pageContext, breadcrumb, skillApi, location }: Props) {
  const { ask, contents, actions } = pageContext || {}
  const { questionnaire } = data.settingYaml
  const { answers, cardId, operatorEscalationKeyword, karakuriWebRootUrl, info } = pageContext

  const handleClick = (path: string) => {
    navigate(path, {
      state: {
        hierarchies: [
          ...breadcrumb.hierarchies,
          { title: pageContext.ask, link: location.pathname },
        ],
      },
    })
  }
  const search = location?.search.indexOf('search=true') > -1
  if (!contents || !cardId) {
    return null
  }
  function feedbackAction(action: 'yes' | 'no', callback: () => void) {
    const feedbackString = action === 'yes' ? 'ok' : 'ng'
    callback()
    KarakuriAnalyticsEvent.feedback(feedbackString)
  }
  return (
    <QAPanel className="faq-answer">
      <CopyLinkButton />
      <Question q={ask || ''} info={info} />
      <Answer
        contents={contents}
        actions={actions}
        onClick={handleClick}
        cardId={cardId}
        answers={answers}
        operatorEscalationKeyword={operatorEscalationKeyword}
        karakuriWebRootUrl={karakuriWebRootUrl}
        messages={{
          notSelected: questionnaire?.notSelected || '上記Q＆Aは参考になりましたか?',
          yes: questionnaire?.yes || '',
          no: questionnaire?.no || '',
          yesForm: questionnaire?.yesForm || '',
          noForm: questionnaire?.noForm || '',
        }}
        info={info}
        session={Session}
        onFeedback={feedbackAction}
        skillApi={skillApi}
      />
      {search && (
        // eslint-disable-next-line no-restricted-globals
        <Button className="faq-go-to-search" color="primary" onClick={() => history.go(-1)}>
          検索結果一覧に戻る
        </Button>
      )}
    </QAPanel>
  )
}
