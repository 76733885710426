import { useApolloClient } from '@apollo/client'
import { navigate, Link, GatsbyLinkProps } from 'gatsby'
import * as React from 'react'

import { Breadcrumb, Search, TagCloud } from '@karakuri-ai/faq-component'

import { SettingYamlCategory } from '../graphql'
import { KarakuriAnalyticsEvent } from '../utils/karakuri-analytics-event'

import { GQL_SEARCH_FAQ } from './graphql'
import { Breadcrumb as BreadcrumbType, TagCloud as TagCloudType, Hierarchy } from './interface'

interface Props {
  botId: string
  breadcrumb: BreadcrumbType
  tagCloud: TagCloudType
  relatedCategories?: SettingYamlCategory[]
  hierarchies: Hierarchy[]
  searchWaitMs: number
  topTitle: string
  searchType: string
}
export function NaviWidget({
  botId,
  hierarchies,
  breadcrumb,
  tagCloud,
  relatedCategories,
  searchWaitMs,
  topTitle,
  searchType,
}: Props) {
  const client = useApolloClient()
  const r = relatedCategories || []
  const tags = tagCloud
    ? [
        { link: '/', title: topTitle },
        ...r.map(c => {
          const title = c.title || ''
          return { link: `/category/${c.id}`, title }
        }),
      ]
    : []
  const handleSearched = (inputValue: string, result: any[]) => {
    KarakuriAnalyticsEvent.search(inputValue, result)
  }
  const LinkComponent = React.forwardRef<
    HTMLAnchorElement,
    GatsbyLinkProps<Record<string, unknown>>
  >(function LinkComponent(props, ref) {
    const found = hierarchies.findIndex(h => h.link === props.to)
    const h = hierarchies.slice(0, found)
    const newProps: Omit<GatsbyLinkProps<Record<string, unknown>>, 'to'> = {}
    if (h.length > 0) {
      newProps.state = { hierarchies: h }
    }
    return <Link ref={ref as any} {...{ ...props, ...newProps }} />
  })
  return (
    <div className="faq-navigation">
      {tagCloud ? (
        <TagCloud tags={tags} LinkComponent={LinkComponent} />
      ) : (
        <Breadcrumb {...breadcrumb} topTitle={topTitle} LinkComponent={LinkComponent} />
      )}

      <Search
        onClick={navigate}
        onSearched={handleSearched}
        searchWaitMs={searchWaitMs}
        search={inputValue =>
          client.query({
            query: GQL_SEARCH_FAQ,
            variables: { botId, inputValue: inputValue || '', searchType },
          })
        }
      />
    </div>
  )
}
